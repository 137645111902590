import revive_payload_client_mzDWhSYMeU from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.12_encoding@0.1.13_eslint@8.57.1_ioredis@_maoaglsugzfbel35aqmfzclymu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jqUNmgFPCK from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.12_encoding@0.1.13_eslint@8.57.1_ioredis@_maoaglsugzfbel35aqmfzclymu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tyz5gxRiJb from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.12_encoding@0.1.13_eslint@8.57.1_ioredis@_maoaglsugzfbel35aqmfzclymu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_Ub7jFNgAqV from "/app/packages/web/modules/sentry/runtime/sentry.client.ts";
import query_Ux2Qt90xcP from "/app/packages/web/plugins/query.ts";
import trpc_client_3tK3TWUrV0 from "/app/packages/web/plugins/trpc.client.ts";
import payload_client_xT6Me1NDxF from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.12_encoding@0.1.13_eslint@8.57.1_ioredis@_maoaglsugzfbel35aqmfzclymu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hCuVTn7oE5 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.12_encoding@0.1.13_eslint@8.57.1_ioredis@_maoaglsugzfbel35aqmfzclymu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Oj3qBVpanO from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.12_encoding@0.1.13_eslint@8.57.1_ioredis@_maoaglsugzfbel35aqmfzclymu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_R59Bb3CR5D from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.12_encoding@0.1.13_eslint@8.57.1_ioredis@_maoaglsugzfbel35aqmfzclymu/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_i1fbXFZycq from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.12_encoding@0.1.13_eslint@8.57.1_ioredis@_maoaglsugzfbel35aqmfzclymu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_RtHMqzb8Sn from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typescript@5.7.2_vue@3.5.6_typescript@5.7.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/packages/web/.nuxt/components.plugin.mjs";
import prefetch_client_4mD4Hw6Eoa from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.12_encoding@0.1.13_eslint@8.57.1_ioredis@_maoaglsugzfbel35aqmfzclymu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_y8ANvLtYfU from "/app/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.7.2__webpack-sources@3.2.3/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import slideovers_6c20hFHdNd from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.9_magicast@0.3.5_rollup@4.21.3_ts-node@10.9.2_@types+node@18.19.50__o7eqko5wqz6cjr63cxegbgxgfq/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_s9VsNVIKUO from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.9_magicast@0.3.5_rollup@4.21.3_ts-node@10.9.2_@types+node@18.19.50__o7eqko5wqz6cjr63cxegbgxgfq/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_B5S79Pfu3t from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.9_magicast@0.3.5_rollup@4.21.3_ts-node@10.9.2_@types+node@18.19.50__o7eqko5wqz6cjr63cxegbgxgfq/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_f31VDMrr4w from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_YtLNRmx837 from "/app/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@18.19.50_terser@5.37.0___fnuvkr6hrxs6lidaph533dinmi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import marker_io_client_3vX6FuptAi from "/app/packages/web/plugins/marker-io.client.ts";
export default [
  revive_payload_client_mzDWhSYMeU,
  unhead_jqUNmgFPCK,
  router_tyz5gxRiJb,
  sentry_client_Ub7jFNgAqV,
  query_Ux2Qt90xcP,
  trpc_client_3tK3TWUrV0,
  payload_client_xT6Me1NDxF,
  navigation_repaint_client_hCuVTn7oE5,
  check_outdated_build_client_Oj3qBVpanO,
  view_transitions_client_R59Bb3CR5D,
  chunk_reload_client_i1fbXFZycq,
  plugin_vue3_RtHMqzb8Sn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4mD4Hw6Eoa,
  plugin_client_y8ANvLtYfU,
  slideovers_6c20hFHdNd,
  modals_s9VsNVIKUO,
  colors_B5S79Pfu3t,
  plugin_client_f31VDMrr4w,
  plugin_YtLNRmx837,
  marker_io_client_3vX6FuptAi
]