import { useQuery } from '@tanstack/vue-query'
import { defineStore, storeToRefs } from 'pinia'

export const useAuth = defineStore('session', () => {
  const keycloak = useKeycloak()
  const mbtv = useMBTV()
  const { isAuthenticated: keycloakAuthenticated } = storeToRefs(keycloak)
  const { isAuthenticated: mbtvAuthenticated } = storeToRefs(mbtv)

  const isAuthenticated = computed(() => {
    if (keycloakAuthenticated.value) return true
    if (mbtvAuthenticated.value) return true
    return false
  })

  const {
    data: user,
    error: userError,
    isLoading: userLoading,
    refetch: refresh,
  } = useQuery({
    queryKey: ['me'] as const,
    queryFn: () => useTRPC().auth.me.query(),
    staleTime: 0,
    retry: false,
    enabled: isAuthenticated,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  })

  watch(isAuthenticated, () => {
    refresh()
  })

  type SessionState =
    | {
      user: (typeof user)['value']
      isCreator: boolean
      isModerator: boolean
      isAdmin: boolean
      status: 'authenticated'
    }
    | {
      user: null
      isCreator: false
      isModerator: false
      isAdmin: false
      status: 'unauthenticated' | 'loading'
    }

  const session = computed(() => {
    return {
      user: userError.value ? null : user.value,
      isCreator: userError.value
        ? false
        : user.value?.roles.some((role) => role.name === 'CREATOR'),
      isModerator: userError.value
        ? false
        : user.value?.roles.some((role) => role.name === 'MODERATOR'),
      isAdmin: userError.value ? false : user.value?.roles.some((role) => role.name === 'ADMIN'),
      status: userLoading.value
        ? 'loading'
        : !userError.value && user.value
            ? 'authenticated'
            : 'unauthenticated',
    } as SessionState
  })

  async function waitForAuth() {
    await until(() => session.value.status).not.toBe('loading')
  }

  return {
    refresh,
    session,
    waitForAuth,
  }
})
